export default [
    {
        Id: 304, Name: "11人制足球", 
        groupTypes: [
            {label: '团体',value: 2}
        ]
    }, {
        Id: 303, Name: "7~8人制足球", 
        groupTypes: [
            {label: '团体',value: 2}
        ]
    }, {
        Id: 301, Name: "5人制足球", 
        groupTypes: [
            {label: '团体',value: 2}
        ]
    }, {
        Id: 302, Name: "沙滩足球", 
        groupTypes: [
            {label: '个人',value: 1},
            {label: '团体',value: 2}
        ]
    }, {
        Id: 3, Name: "足球", 
        groupTypes: [
            {label: '团体',value: 2}
        ]
    }, {
        Id: 802, Name: "5人制篮球", 
        groupTypes: [
            {label: '团体',value: 2}
        ]
    }, {
        Id: 801, Name: "3人制篮球", 
        groupTypes: [
            {label: '团体',value: 2}
        ]
    }, {
        Id: 8, Name: "篮球", 
        groupTypes: [
            {label: '团体',value: 2}
        ]
    }, {
        Id: 6, Name: "气排球", 
        groupTypes: [
            {label: '团体',value: 2}
        ]
    }, {
        Id: 4, Name: "乒乓球",
    }, {
        Id: 5, Name: "羽毛球",
    }, {
        Id: 9, Name: "网球"
    }, {
        Id: 11, Name: "城市跑"
    }, {
        Id: 10, Name: "场地跑"
    }, {
        Id: 1, Name: "桥牌"
    }, {
        Id: 7, Name: "围棋"
    }, {
        Id: 12, Name: "电子竞技"
    }, {
        Id: 13, Name: "掼蛋", 
        groupTypes: [
            {label: '团体',value: 2},
            {label: '双人组合',value: 3}
        ]
    }, {
        Id: 14, Name: "太极拳"
    }, {
        Id: 15, Name: "汽摩赛"
    }, {
        Id: 17, Name: "力量举"
    }, {
        Id: 19, Name: "TCG"
    }, {
        Id: 20, Name: "全健排舞"
    }, {
        Id: 21, Name: "健身气功"
    }, {
        Id: 22, Name: "匹克球"
    }, {
        Id: 23, Name: "普拉提"
    }, {
        Id: 24, Name: "综合格斗"
    }, {
        Id: 25, Name: "排球"
    }, {
        Id: 26, Name: "巧固球"
    }, {
        Id: 27, Name: "田径"
    }, {
        Id: 28, Name: "中国象棋"
    }, {
        Id: 29, Name: "国际象棋"
    }, {
        Id: 2, Name: "职沙会"
    }    
];