<template>
    <div class="container" id="LookCompetitionDetail">
        <el-page-header @back="$router.back()" content="查看赛事信息"></el-page-header>
        <div class="LookCompetitionDetail_content">
            <el-descriptions title="赛事信息" :column="1" :labelStyle="{'width':'100px','justify-content': 'end','padding-right':'12px','margin-right':'0','box-sizing':'border-box'}">
                <el-descriptions-item label="赛季名称">{{editInfo.SeasonName}}</el-descriptions-item>
                <el-descriptions-item label="运动项目">{{editInfo.EventsName || '无'}}</el-descriptions-item>
                <el-descriptions-item label="赛事全称">{{editInfo.LeagueFullName}}</el-descriptions-item>
                <el-descriptions-item label="赛事简称">{{editInfo.LeagueShortName || '无'}}</el-descriptions-item>
                <el-descriptions-item label="比赛形式">{{editInfo.IsOnline ? '线上赛' : '线下赛'}}</el-descriptions-item>
                <el-descriptions-item label="比赛平台url" v-if="editInfo.IsOnline">{{editInfo.LeagueSite || '无'}}</el-descriptions-item>
                <el-descriptions-item label="赛事logo">
                    <img :src="editInfo.LogoUrl" style="height:100px;" v-if="editInfo.LogoUrl"/>
                    <span v-else>无</span>
                </el-descriptions-item>
                <el-descriptions-item label="封面照片">
                    <img :src="editInfo.CoverUrl" style="height:100px;" v-if="editInfo.CoverUrl"/>
                </el-descriptions-item>
                <el-descriptions-item label="特殊状态">
                    <span :style="editInfo.AbnormalStatus > 0 ? 'color:red' : ''">{{['无（正常）','赛事取消','赛事延期','赛事中断'][editInfo.AbnormalStatus]}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="公告标题" v-if="editInfo.AbnormalStatus > 0 && editInfo.BulletinUrl">
                    <span>{{editInfo.BulletinTitle}}</span>
                    <el-link type="primary" :href="editInfo.BulletinUrl" target="_blank">点击查看</el-link>
                </el-descriptions-item>
                <el-descriptions-item label="比赛时间">
                    <span v-if="editInfo.GameTime">{{editInfo.GameTime.BeginTime | dateFormat('yyyy-MM-dd HH:mm:ss')}} ~ {{editInfo.GameTime.EndTime | dateFormat('yyyy-MM-dd HH:mm:ss')}}</span>
                    <span v-else>未定</span>
                </el-descriptions-item>
                <el-descriptions-item label="比赛地点">
                    <div style="width: 100%;max-width: 700px;display:flex;flex-wrap: wrap;">
                        <el-tag
                            v-for="(item,index) in editInfo.GamePlace" :key="index"
                            :closable="false"
                            disable-transitions effect="plain" size="small"
                            style="margin-right:10px;margin-bottom:5px"
                        >
                            {{item.RegionName}}
                        </el-tag>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item :label="item.Kind" v-for="(item,index) in Units" :key="index" >
                    <div style="width: 100%;max-width: 700px;display:flex;flex-wrap: wrap;">
                        <el-tag
                            v-for="(unitItem,unitIndex) in item.Units" :key="unitIndex"
                            disable-transitions effect="plain" size="small"
                            style="margin-right:10px;margin-bottom:5px"
                        >
                            {{unitItem}}
                        </el-tag>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item label="赛事详情">
                    <Editor :value="editInfo.Comment" v-if="editInfo.Comment" disabled></Editor>
                </el-descriptions-item>
            </el-descriptions>
            <el-divider></el-divider>
            <el-descriptions title="赛事分组信息"></el-descriptions>
            <el-card shadow="never" v-for="(item,index) in handleEventsGroupList" :key="index" style="width:100%;max-width:700px;box-sizing: border-box;margin-bottom:20px">
                <el-descriptions title="" :column="1" :labelStyle="{'width':'100px','justify-content': 'end','padding-right':'12px','margin-right':'0','box-sizing':'border-box'}">
                    <el-descriptions-item label="比赛项目">{{item.Name}}</el-descriptions-item>
                    <el-descriptions-item label="组别">
                        <div style="width: 100%;display:flex;flex-wrap: wrap;">
                            <el-tag
                                v-for="(groupItem,groupIndex) in item.groups" :key="groupIndex"
                                :closable="false"
                                disable-transitions effect="plain" size="small"
                                style="margin-right:10px;margin-bottom:5px"
                            >
                                {{groupItem.GroupName}}（{{getGroupTypeText(item, groupItem)}}）
                            </el-tag>
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
            </el-card>
            <el-divider></el-divider>
        </div>
    </div>
</template>
<script>
import {
    GetSubleagueDetail
} from '@/api'
import Editor from '@/components/Editor';
import eventsConfig from './eventsConfig';
export default {
    components: { 
        Editor
    },
    data(){
        return {
            subLeagueId: 0,
            editInfo:{},
            groups: []
        }
    },
    created(){
        let subLeagueId = +(this.$route.params.subLeagueId || 0);
        this.subLeagueId = subLeagueId;
        this.getDetail();
        
    },
    computed: {
        Units(){
            if(!this.editInfo.AllUnits) return;
            let units = this.editInfo.AllUnits;
            return units;
        },
        handleEventsGroupList(){
            let eventsList = [];
            this.groups.forEach(groupItem=>{
                let obj = eventsList.find(item=>item.Id == groupItem.EventsId);
                if(obj){
                    obj.groups.push(groupItem);
                }else{
                    eventsList.push({
                        Id: groupItem.EventsId,
                        Name: groupItem.EventsName,
                        groups: [groupItem]
                    })
                }
            })
            return eventsList;
        }
    },
    methods: {
        getDetail(){
            GetSubleagueDetail({
                SubLeagueId: this.subLeagueId
            }).then(data=>{
                if(data.Code == 0){
                    var editInfo = data.EditInfo;
                    this.editInfo = editInfo;
                    this.groups = editInfo.GroupList || [];
                }
            })
        },
        
        getGroupTypes(eventsItem){
            let obj = eventsConfig.find(item=>item.Id == eventsItem.Id);
            if(obj && obj.groupTypes){
                return obj.groupTypes;
            }else{
                return [
                    { value: 1, label: '个人' },
                    { value: 2, label: '团体' },
                    { value: 3, label: '双人组合' }
                ]
            }
        },
        getGroupTypeText(eventsItem, groupItem){
            let groupTypes = this.getGroupTypes(eventsItem);
            return (groupTypes.find(item=>item.value == groupItem.GroupType) || {}).label + (groupItem.IsHonored ? '，嘉宾' : '');
        }
    }
}
</script>
<style>
#LookCompetitionDetail .el-descriptions-item__content{
    width: 100%;
}
#LookCompetitionDetail .el-descriptions-item__content .editor{
    width: 100%;
}
#LookCompetitionDetail .editor .tox-editor-header{
    display: none;
}
#LookCompetitionDetail .el-card .el-card__body{
    padding: 20px 10px 0 0px !important;
}
</style>